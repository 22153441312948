@import 'styles/variables.scss';
.detail-incidents {
  .note {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #f9f9f9;
    height: calc(100vh - 220px);
    .infinite-scroll-component__outerdiv {
      overflow: hidden;
      height: 100%;
    }
    .inifinite-scroll {
      display: flex;
      flex-direction: column-reverse !important;
      overflow: auto;
      max-height: 100%;
    }
    .note-list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
      max-height: 100%;
      .space {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.5 * $space-size-base 2 * $space-size-base;
        // .ant-space-item:last-child {
        //   padding-bottom: 2*$space-size-base;
        // }
        // .ant-space-item {
        //   width: 100%;

        .note-list-item {
          margin-top: 2 * $space-size-base;
          background: #ffffff;
          box-shadow: 0px 0px 4px #c6c6c8;
          border-radius: 0.5 * $space-size-base;
          padding: 2 * $space-size-base;
          width: 100%;
          height: 100%;
          .header-item {
            display: flex;
            align-items: center;
            position: relative;
            .title {
              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              letter-spacing: 0.1px;
              color: #343434;
              margin-bottom: 1.5 * $space-size-base;
              margin-right: 20px;
            }
            // .title:after {
            //   content: ".";
            //   position: absolute;
            //   font-size: 2rem;
            //   top: -10px;
            //   margin-left: 5px;
            //   color: #c4c4c4;
            // }
            .text {
              padding-bottom: 10px;
              font-size: $font-size-13;
              line-height: $line-height-rate-nano;
              display: flex;
              align-items: center;
              letter-spacing: 0.1px;
              color: #818181;
            }
          }

          .question-text {
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            letter-spacing: 0.1px;
            color: #343434;
            white-space: pre-wrap;
            word-break: break-all;

          }
        }
      }
    }
  }

  .form-chat {
    padding: 2 * $space-size-base;
    background: #fff;
    .item-textarea {
      border: 1px solid #007aff;
      box-sizing: border-box;
      border-radius: 0.5 * $space-size-base;
    }
    .item-btn {
      margin: 0;
      text-align: right;
      .btn-send {
        border-radius: 0.5 * $space-size-base;

        color: #ffffff;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;

        &:disabled {
          background: #c6c6c8;
        }
      }
    }
  }
}
// }
