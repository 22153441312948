.other-setting {
  padding-left: 16px;
  padding-top: 20px;

  .wrap_header {
    display: flex;
    gap: 20px;

    .title {
      font-size: 17px;
      color: #343434;
    }
  }

  .wrap-text-des {
    margin-top: 16px;

    .upload_off {
      font-size: 15px;
      color: #ff3b30;
    }

    .upload_on {
      font-size: 15px;
      color: #34c759;
    }

    .text-des {
      color: #343434;
      font-size: 15px;
    }
  }
}

.other-setting-loading {
  padding-left: 16px;
  padding-top: 20px;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
