@import 'styles/variables.scss';
.setting {
  .standby-org {
    padding: 16px 16px 60px;

    .search-input {
      height: 32px;
      border-radius: 4px;
      padding: $space-size-tiny $space-size-base;
    }

    .text-org-name-trancate {
      max-width: 600px;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .checkable-action-container {
      margin: 15px 0;

      .number-selected {
        color: #007aff;
      }

      .btn-move,
      .btn-hide {
        font-size: $font-size-15;
      }

      .btn-move {
        color: #18bcf3;
        border: 1px solid #18bcf3;
      }
    }

    .wrap-tree {
      display: flex;
      justify-content: space-between;

      .text-org-root {
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        align-items: center;
      }
      .text-org-child {
        color: #343434;
        display: flex;
        align-items: center;
      }

      .text-org-root,
      .text-org-child {
        > .edit-hidden {
          display: none;
        }

        > .edit-show {
          display: none;
        }

        .wrap-button {
          display: flex;
          button {
            height: 40px;
          }

          button:nth-child(1) {
            margin-left: 10px;
          }

          .input-edit {
            height: 40px;
          }
        }
      }

      .text-org-root:hover,
      .text-org-child:hover {
        > .edit-show {
          display: block;
          cursor: pointer;
          margin-left: 5px;
          margin-top: -5px;
        }
      }

      .org-hidden {
        display: flex;
        .item {
          margin-right: 10px;
        }

        .txt-hidden {
          font-size: $font-size-13;
          line-height: 120%;
          letter-spacing: 0.1px;
          color: #ff3b30;
          margin-top: -5px;
        }
      }

      .wrap-tree-left {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        height: 48px;

        .item {
          margin-left: 10px;
          line-height: 48px;
        }

        .item.draggable {
          cursor: pointer;
          display: none;
        }

        .icon-open-root {
          color: rgba(0, 0, 0, 0.85);
          cursor: pointer;
        }
        .icon-open-child {
          color: #007aff;
          cursor: pointer;
        }
      }

      .wrap-tree-right {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .wrap-access-code {
          max-width: 300px;

          .txt {
            max-width: 300px;
            text-align: right;
            overflow: hidden;
            white-space: nowrap; /* Don't forget this one */
            text-overflow: ellipsis;
            margin-top: 5px;
          }
        }

        .wrap-action {
          display: flex;
          margin-left: 10px;
          width: 150px;
          > div {
            margin-left: 20px;
            width: 50px;
          }
        }

        .wrap-add {
          width: 20px;

          .ic-create,
          .ic-archive {
            cursor: pointer;
          }
        }

        .ic-file,
        .ic-swap {
          cursor: pointer;
        }
      }
    }

    .tree-root {
      min-height: 48px;
      height: auto;
      margin-top: 10px;
      background: #ffdeeb;
      border-radius: 2px;
    }

    .tree {
      min-height: 50px;
      height: auto;
      margin-top: 10px;
      background: rgba(64, 158, 255, 0.1);
      border-radius: 4px;
    }

    .tree:hover {
      .item.draggable {
        display: block;
      }
      border: 1px solid #409eff;
      cursor: pointer;
    }

    .tree-hidden {
      min-height: 48px;
      height: auto;
      margin-top: 10px;
      background: #f4f4f5;
      border-radius: 2px;
    }

    .lv2 {
      margin-left: 50px;
    }
    .lv3 {
      margin-left: 100px;
    }
  }

  .organization-setup {
    padding: 2 * $space-size-base;
    padding-bottom: 60px;
    // height: 100%;
    // overflow-y: scroll;

    .head {
      margin-bottom: $space-size-base;
      .search-input {
        max-width: 320px;
        border-radius: 0.5 * $space-size-base;

        .ant-input-prefix {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .cb-expand {
        span {
          &:nth-child(2) {
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-nano;

            color: #343434;
          }
        }
      }
      
      .txt-access-code {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-11;
        line-height: $line-height-rate-nano;
        /* or 13px */

        letter-spacing: 0.1px;
        text-transform: uppercase;

        color: #000000;
      }

      div {
        &:last-child {
          // text-align: center;
          span {
            margin-left: 40px;
          }
        }
      }
    }

    .cl-acog {
      border-radius: 0.5 * $space-size-base;
      .ant-collapse-header {
        background: #ffa4c9;
        border: 1px solid #ffa4c9;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* System / White */

        color: #ffffff;
        /* Brand / Pink */
      }
    }

    .checkable-action-container {
      margin: $space-size-large 0;

      .selected-txt-block {
        .txt-selected,
        .number-selected {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-17;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }

        .number-selected {
          color: #007aff;
        }
      }

      .btn-move,
      .btn-hide {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
      }

      .btn-move {
        color: #18bcf3;
        border: 1px solid #18bcf3;
      }
    }

    .tree-acog {
      .ant-tree-list-holder-inner {
        display: unset !important;
      }
      .ant-tree-treenode {
        // width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: $space-size-base;

        .ant-tree-switcher {
          display: flex;
          align-items: center;

          .ant-tree-switcher-leaf-line {
            margin-top: 23px;
          }
        }

        .ant-tree-node-selected {
          background: none;
        }

        .ant-tree-switcher-icon {
          font-size: $font-size-13;
          color: #007aff;
        }

        .ant-tree-node-content-wrapper {
          width: 100%;
          .ant-tree-title {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            /* identical to box height, or 25px */

            letter-spacing: 0.1px;

            /* System / White */

            width: 100%;
            .tree-header-og,
            .tree-header-school,
            .tree-header-grade {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 48px;
              border: 1px solid #e0e0e0;
              border-radius: 0.5 * $space-size-base;
              background: #ffa4c9;
              padding: 0 $space-size-large;

              .ant-typography-edit-content {
                margin: 0;
                width: 400px;
              }

              .ant-typography-edit-content-confirm {
                display: none;
              }
            }

            .tree-header-og {
              .ant-typography {
                color: #fff;
                overflow: hidden;
                white-space: nowrap; /* Don't forget this one */
                text-overflow: ellipsis;
                max-width: 300px;

                .ant-typography-edit {
                  display: none !important;
                }

                &:hover {
                  .ant-typography-edit {
                    display: inline-block !important;
                  }
                }
              }
              .ant-typography-edit-content {
                padding-left: 2 * $space-size-base;
              }
            }

            .tree-header-school,
            .tree-header-grade {
              .ant-space-item {
                max-width: 300px;
                overflow: hidden;
                white-space: nowrap; /* Don't forget this one */
                text-overflow: ellipsis;

                .ant-typography {
                  color: #343434;
                  max-width: 300px;

                  .ant-typography-edit {
                    display: none !important;
                  }

                  &:hover {
                    .ant-typography-edit {
                      display: inline-block !important;
                    }
                  }
                }

                .ant-typography-edit-content {
                  padding-left: 2 * $space-size-base;
                }
              }

              background: rgba(64, 158, 255, 0.1);
            }

            .tree-header-school.hidden,
            .tree-header-grade.hidden {
              background: rgba(144, 147, 153, 0.1);
              /* Gray 5 */

              border: 1px dashed #e0e0e0;

              .txt-hidden {
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-13;
                line-height: $line-height-rate-nano;
                /* identical to box height, or 16px */

                letter-spacing: 0.1px;

                /* System / Red */

                color: #ff3b30;
                margin-top: -5px;
              }
            }

            .ic-swap {
              width: 40px;
            }

            .wrap-access-code {
              max-width: 300px;

              .txt {
                max-width: 300px;
                text-align: right;
                overflow: hidden;
                white-space: nowrap; /* Don't forget this one */
                text-overflow: ellipsis;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}

// modal
.md-og,
.md-gen-qr-code,
.md-deactive-code,
.md-move-og {
  width: 800px !important;
  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    /* or 28px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }

  .btn-ok {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    /* System / White */

    color: #ffffff;
    border-radius: 0.5 * $space-size-base;
  }

  .btn-close {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;

    color: #343434;
    border-radius: 0.5 * $space-size-base;
  }
}

.md-og,
.md-gen-qr-code,
.md-deactive-code,
.md-move-og {
  width: 1120px !important;
  &.md-og-lg {
    width: 80vw !important;
  }
  .ant-input,
  .ant-select,
  .ant-btn {
    border-radius: 0.5 * $space-size-base;
  }

  .og-level-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 50px;

    .btn-header {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;

      /* System / Red */

      color: #ff3b30;
    }
  }

  .ant-modal-body {
    padding: 2 * $space-size-base;
  }
  .ant-divider-horizontal {
    margin-top: $space-size-base;
    margin-bottom: $space-size-base;
  }

  .ant-form-item {
    margin-bottom: 2 * $space-size-base;
  }

  .ant-form-item-label,
  .lb-form {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    letter-spacing: 0.1px;

    /* Text / Primary */

    color: #343434;
    padding-bottom: 2px !important;
  }

  .input-og-name,
  .input-working-time,
  .input-home-message,
  .input-incident-message {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    color: #343434;
  }

  .label-info {
    margin-top: $space-size-base;
    .ant-space-item {
      .txt {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-base;

        color: #007aff;
        text-transform: uppercase;
      }

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
        font-size: $font-size-20;
        margin-top: 5px;
      }
    }
  }

  .div-label-info {
    margin-top: 1.5 * $space-size-base;
  }

  .wrap-access-codes {
    max-height: 425px;
    overflow-y: scroll;
    overflow-x: hidden;
    // display: flex;
    // flex-direction: column-reverse;
  }

  .input-access-code {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    border-radius: 0.5 * $space-size-base;
  }

  .input-code-checked {
    border-color: #34c759;

    &:disabled {
      background: #fff;
      color: #343434;
      &:hover,
      &:focus {
        border-color: #34c759;
      }
    }
  }

  .input-code-checked-deactive {
    .txt-deactivated {
      color: #ff3b30;
    }

    .ant-input-disabled {
      &:disabled {
        color: #343434;
        font-size: $font-size-15;
      }
    }
  }

  .input-code-checked-disabled {
    &:disabled {
      color: #343434;
      background-color: #fff;
    }
  }

  .btn-check-availability,
  .btn-confirm-use,
  .btn-cancel {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;

    &:disabled {
      border: none;
      color: #ffffff;
      background-color: #e0e0e0;
    }
  }

  .radio-branding,
  .radio-og-type {
    .ant-radio-wrapper {
      padding: 7px;
      min-width: 660px;

      span {
        &:nth-child(2) {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;

          letter-spacing: 0.1px;

          /* Primary Text */

          color: #343434;
        }
      }
    }

    .ant-radio-wrapper-checked {
      border-radius: 0.5 * $space-size-base;
      border: 1px solid #007aff;
      background: rgba(64, 158, 255, 0.1);
      .ant-radio-checked .ant-radio-inner {
        border-color: #007aff;
        &:after {
          background-color: #007aff;
        }
      }
    }
  }

  .position-structure {
    .ant-cascader-picker-label {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
      height: 30px;
    }
  }
}

.md-gen-qr-code,
.md-deactive-code {
  width: 755px !important;

  .input-access-code {
    &:disabled {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;

      color: #343434;
      background-color: #fff;
    }
  }

  .btn-download {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    /* System / White */

    color: #ffffff;
  }

  .contain-qr-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;

    .txt-description-qr-code {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* or 20px */

      letter-spacing: 0.5px;

      color: #000000;
    }
  }
}

.md-deactive-code {
  .space-des,
  .space-sure {
    margin-bottom: 30px;
    .txt-deactive {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* or 25px */
      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }
  }

  .space-sure {
    margin-top: 30px;
  }
}

.md-move-og {
  width: 800px !important;
  .input-og-name {
    &:disabled {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;

      color: #343434;
    }
  }

  .move-to {
    margin-top: 3 * $space-size-base;
    margin-bottom: 130px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .cas-og {
      margin-top: 0.5 * $space-size-base;
      width: 100%;
      .ant-select-selector {
        border-radius: 4px;
      }

      .ant-cascader-picker-label {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
        height: 30px;
      }
    }
  }
}

.md-hide-delete {
  width: 800px !important;

  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    /* or 28px */

    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }

  .ant-btn {
    border-radius: 0.5 * $space-size-base;
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
  }

  .txt-info {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    /* identical to box height, or 25px */

    letter-spacing: 0.1px;

    /* Text / Primary */

    color: #343434;
  }

  .space {
    height: 60px;
  }
}

.cas-og-menu {
  .ant-cascader-menu-item {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    letter-spacing: 0.1px;
    color: #343434;
    max-width: 650px;

    .anticon-right {
      color: #007aff;
      font-size: $font-size-12;
    }

    .ant-cascader-menu-item-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-cascader-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: unset;
  }
}
