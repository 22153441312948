@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$font-family: 'Noto Sans JP' !important;
$primary-background: #001529;
$primary-color: lightseagreen;
$og-btn-color: #282828;
$oa-btn-color: #18bcf3;

$content-width: 1366px;
$font-size-base-px: 15px;

$font-size-8: 1rem * (8px / $font-size-base-px); //8px;
$font-size-10: 1rem * (10px / $font-size-base-px); //10px;
$font-size-11: 1rem * (11px / $font-size-base-px); //11px;
$font-size-12: 1rem * (12px / $font-size-base-px); //12px;
$font-size-13: 1rem * (13px / $font-size-base-px); //13px;
$font-size-14: 1rem * (14px / $font-size-base-px); //14px;
$font-size-15: 1rem; //15px;
$font-size-16: 1rem * (16px / $font-size-base-px); //16px;
$font-size-17: 1rem * (17px / $font-size-base-px); //17px;
$font-size-18: 1rem * (18px / $font-size-base-px); //18px;
$font-size-20: 1rem * (20px / $font-size-base-px); //20px;
$font-size-21: 1rem * (21px / $font-size-base-px); //21px;
$font-size-24: 1rem * (24px / $font-size-base-px); //24px;
$font-size-25: 1rem * (25px / $font-size-base-px); //25px;

$font-size-36: 1rem * (36px / $font-size-base-px); //36px;
$font-size-48: 1rem * (48px / $font-size-base-px); //48px;

$font-size-none: 0rem;

$space-size-tiny: 4px;
$space-size-base: 8px;
$space-size-large: 14px;
$space-size-xl: 32px;

$line-height-rate-nano: 1.2;
$line-height-rate-micro: 1.3;
$line-height-rate-small: 1.4;
$line-height-rate-base: 1.5;
$line-height-rate-medium: 1.6;
$line-height-rate-large: 1.7;
$line-height-rate-xlarge: 1.8;

// form
$input-height-30: 30px;
