@import 'styles/variables.scss';
body {
  min-height: 768px;
  min-width: 1366px;
  font-family: $font-family;
}
html {
  font-size: $font-size-base-px;
  font-family: $font-family;

  ::-webkit-scrollbar-track {
    border-radius: 1.25 * $space-size-base;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: $space-size-base;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1.25 * $space-size-base;
    background-color: #dedede;
  }
}

#root {
  overflow: hidden;
  min-height: 100%;
  position: relative;
}

.ant-notification-notice {
  width: auto !important;
}

span.break-line-localize,
div.break-line-localize {
  white-space: pre-line !important;
}

button.copy-access-code-btn {
  background: $oa-btn-color;
}

.max-text-div {
  max-width: 53rem;
}

.ant-select-dropdown {
  padding: 0 !important;
}

.modal {
  .ant-input,
  .ant-btn {
    border-radius: 0.5 * $space-size-base;
  }

  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    /* or 28px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }

  .btn-save,
  .btn-add {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    /* System / White */

    color: #ffffff;
    border-radius: 0.5 * $space-size-base;
  }

  .btn-cancel,
  .btn-close {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;

    color: #343434;
    border-radius: 0.5 * $space-size-base;
  }

  .ant-form-item-label {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    letter-spacing: 0.1px;

    /* Text / Primary */

    color: #343434;
    padding-bottom: 2px !important;
    display: inline-flex;
  }

  .txt-confirm {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* or 20px */

    // display: flex;
    // align-items: center;
    letter-spacing: 0.5px;

    /* Text / Primary */

    color: #343434;
  }
}

.modal-change-pw {
  width: 800px !important;
  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    /* or 28px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }

  .txt-info-change-pw {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* or 20px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Text / Primary */

    color: #343434;
  }

  .btn-ok {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    /* System / White */

    color: #ffffff;
    border-radius: 0.5 * $space-size-base;
  }

  .btn-close {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;

    color: #343434;
    border-radius: 0.5 * $space-size-base;
  }
}

.list-question .ant-checkbox-inner:after {
  top: 0;
}

.ant-table table {
  overflow-x: hidden;
}

.ant-table-body {
  overflow-x: hidden;
}

.ant-form label {
  white-space: pre-wrap;
}

.ant-checkbox > span {
  white-space: pre-wrap;
}

.ant-checkbox-inner:after {
  top: 25%;
}

.table-header {
  .upIcon,
  .normalIcon,
  .downIcon {
    position: relative;
  }
  .normalIcon::after {
    content: url('/images/Vector.png');
    position: absolute;
    // top: 2px;
    right: -13px;
    width: 11px;
    height: 11px;
    //background: red;
  }

  .upIcon::after {
    content: url('/images/arrow-upward.png');
    position: absolute;
    // top: 2px;
    right: -18px;
    width: 16px;
    height: 16px;
    //background: red;
    background-image: url('/images/arrow-upward.png');
  }

  .downIcon::after {
    content: url('/images/arrow-down.png');
    position: absolute;
    // top: 2px;
    right: -18px;
    width: 16px;
    height: 16px;
    background-image: url('/images/arrow-down.png');
    //background: red;
  }
}

.ant-table-column-sorter {
  //visibility: hidden !important;
  display: none;
}

.ant-select-item-option-content .pre-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
}

.dropdown-organizations {
  ::-webkit-scrollbar {
    display: none !important;
    width: 0;
    scrollbar-width: none;
  }

  .txt-og {
    white-space: pre;
  }
  .txt-school {
    white-space: pre;
  }
  .organizations {
    min-width: 380px;
    max-height: 552px;
    box-shadow: 0px 0px 16px rgba(198, 198, 200, 0.5);
    border-radius: 0.5 * $space-size-base;
    padding: 2 * $space-size-base;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none !important;
      width: 0;
    }
    scrollbar-width: none;
    .ant-card-body {
      padding: 0;
      height: 100%;

      .show-hidden {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 20px;

        .txt {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }

        .switch-hidden {
          background-color: #ff3b30;
        }

        .switch-hidden.ant-switch-checked {
          background-color: #34c759;
        }
      }

      .wrap-input {
        padding: 3px;

        .search-input {
          border: none;
          background: rgba(144, 147, 153, 0.1);
          border-radius: 0.5 * $space-size-base;

          .ant-input {
            background: none;
          }

          .ant-input-prefix {
            color: rgba(0, 0, 0, 0.54);
          }

          ::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            /* identical to box height, or 25px */

            letter-spacing: 0.1px;

            /* Grey / Opaque */

            color: #c6c6c8;
          }
        }
      }

      .txt-oa {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* System / Blue */

        color: #007aff;
        margin: 10px 0;
        padding-left: 0;

        &:disabled {
          color: #c6c6c8;
        }
      }

      .tree-organizations {
        margin-top: 1.5 * $space-size-base;
        // padding-bottom: 24px;
        .ant-tree-treenode {
          min-height: 45px;
          .ant-tree-title {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            /* identical to box height, or 25px */

            letter-spacing: 0.1px;

            /* Text / Primary */

            color: #343434;
          }

          .ant-tree-switcher {
            color: #007aff;
            .anticon {
              font-size: $font-size-12;
              font-weight: bold;
            }
          }

          .tree-header-school.hidden,
          .tree-header-grade.hidden {
            margin-bottom: $space-size-base;
            .txt-hidden {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-13;
              line-height: $line-height-rate-nano;
              /* identical to box height, or 16px */

              letter-spacing: 0.1px;

              /* Icon */

              color: rgba(0, 0, 0, 0.54);
            }
          }

          .tree-header-og {
            max-width: 325px;
            overflow: hidden;
            white-space: nowrap; /* Don't forget this one */
            text-overflow: ellipsis;
          }

          .tree-header-school,
          .tree-header-grade {
            .ant-space-item {
              max-width: 290px;
              overflow: hidden;
              white-space: nowrap; /* Don't forget this one */
              text-overflow: ellipsis;
            }
          }

          .tree-header-og.is-oa,
          .is-role-grade {
            .txt-og,
            .txt-grade {
              color: #c6c6c8;
              white-space: pre;
            }
          }
        }
      }
    }
  }
}

.btn-account-og > span {
  white-space: pre !important;
}

.btn-account-og {
  min-width: 150px;
  max-width: 300px;
  padding: 0 $space-size-large;
  height: 40px;
  border: 2px solid $og-btn-color;
  border-radius: $space-size-tiny;
  font-size: $font-size-15;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  // color: #3e3e3e;
  color: $og-btn-color;

  &:hover,
  &:focus {
    // color: #3e3e3e;
    color: $og-btn-color;
    border: 2px solid $og-btn-color;
  }

  .icon-right {
    width: 35px;
    height: 24px;
    background: #ffffff;
    color: $og-btn-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5 * $space-size-base;
    font-size: $font-size-14;
    padding: 0;
    margin-left: $space-size-large;
    border: 2px solid $og-btn-color;
  }
  .icon-right.is-oa {
    border: 2px solid $oa-btn-color;
    color: $oa-btn-color;
  }

  .anticon-down {
    margin-left: 10px;
  }

  span:nth-child(2) {
    text-align: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
  }
}

.btn-account-og.is-oa {
  background: #fff;
  color: $oa-btn-color;
  font-size: $font-size-15;
  border: 2px solid $oa-btn-color;
  min-width: 150px;
  max-width: 300px;
  &:hover,
  &:focus {
    border: 2px solid $oa-btn-color;
  }
}

.modal-confirm {
  .ant-modal-body {
    min-height: 120px;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

span.customize-required-class::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.md-opt-in {
  width: 1366px !important;

  .board {
    margin-top: 30px;
    margin-bottom: 75px;

    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;
      padding: 16px 8px;

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .ic-expand {
        color: #007aff;
        margin-right: 1.5 * $space-size-base;
        font-size: $font-size-13;
      }

      .fake-expand {
        margin-right: $space-size-large;
        color: transparent;
      }

      .ant-checkbox-checked::after {
        border: 1px solid #007aff;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #007aff;
        border-color: #007aff;
      }

      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #e3e3e3;
      }
      .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: #fff;
        opacity: 0.5;
      }

      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
      }
    }
  }

  .wrap-txt-info {
    margin-bottom: 3 * $space-size-base;

    .txt-info {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* or 20px */

      letter-spacing: 0.5px;

      /* Text / Primary */

      color: #343434;
    }
  }
}
.ant-picker-panel-container {
  border-radius: 0.5 * $space-size-base;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(64, 158, 255, 0.1);
}

.ant-select-item-option-content,
.ant-picker-time-panel-cell-inner,
.ant-picker-cell-inner {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-13;
  line-height: $line-height-rate-base;
  /* identical to box height, or 25px */

  letter-spacing: 0.1px;

  /* Text / Primary */

  color: #343434;
}

.ant-picker-cell-disabled {
  .ant-picker-cell-inner {
    // opacity: 0.5;
    color: #c6c6c8;
  }
}

.ant-picker-cell-disabled::before {
  background: #fff;
}

.ant-picker-cell-today {
  .ant-picker-cell-inner {
    color: #18c9f3;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    border-radius: $space-size-large;
  }
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-next-icon,
.ant-picker-super-prev-icon {
  color: #343434;
}

.ant-picker-content {
  thead {
    border-bottom: 1px solid rgba(144, 147, 153, 0.2);
    th {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* or 18px */

      color: #343434;
    }
  }
}

.ant-picker-header {
  border-bottom: none;
}
.ant-picker-date-panel,
.ant-picker-panel-container,
.ant-picker-panel {
  min-width: 325px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-header-view {
  font-style: normal;

  font-size: $font-size-15;
  /* identical to box height, or 25px */

  letter-spacing: 0.1px;

  /* Text / Primary */

  color: #343434;
}

.ant-picker-ok {
  button {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    /* System / White */

    color: #ffffff;
    border-radius: 0.5 * $space-size-base;
    height: 35px;
    width: 50px;
  }
}

.not-support-screen,
.not-found-page {
  .ant-result-title {
    font-size: 30px;
  }

  .ant-result-subtitle {
    font-style: normal;
    font-size: $font-size-17;
    color: #343434;
  }
}

.ant-form-item-explain-error {
  font-size: $font-size-13;
}

.modal-view-pdf {
  .react-pdf__Document {
    .react-pdf__Page {
      canvas {
        margin: 0 auto;
      }
    }
  }
}

.list-notifications {
  // height: 500px;
  // overflow-y: scroll;
  div.ant-typography-single-line:empty {
    display: none;
  }
  div.ant-typography,
  .ant-typography p {
    margin-bottom: 4px;
  }
}

.emoji-mart-preview {
  display: none;
}

.not-found-page {
  .btn-back {
    font-size: $font-size-15;
  }
}

// popup notice response
.modal-notice-response {
  height: 636px;
  .ant-modal-content {
    border-radius: 0.5 * $space-size-base;
    height: 100%;

    .popup-response {
      .popup-icon {
        text-align: center;
        margin: 60px 0;
      }

      .popup-info {
        margin: 30px 0;

        .txt-title {
          font-style: normal;

          font-size: $font-size-21;
          line-height: $line-height-rate-micro;
        }
      }

      .popup-description {
        margin-bottom: 30px;
        .txt-description {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          color: #343434;
          margin-bottom: 3 * $space-size-base;
        }
      }

      .btn-ok {
        height: 50px;
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
      }
    }
  }
}

@media (max-width: 786px) {
  .header .menu-tab {
    margin-left: 0 !important;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    font-size: 10px !important;
  }

  .share-incident {
    width: 210mm;
    min-height: 297mm;
    // padding-left: 20mm !important;
    padding-right: 20mm !important;
    width: auto !important;
    height: auto !important;
    border: initial;
    border-radius: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    letter-spacing: 0px !important;

    .share-incident-header {
      .logo {
        width: 250px !important;
        height: 100px !important;
      }
    }
  }

  #root {
    width: auto !important;
    height: auto !important;
    min-height: auto !important;
    min-width: auto !important;
    overflow: auto !important;
    letter-spacing: 0px !important;
  }
}

.wrap-fileType {
  width: 50px;
  height: 50px;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

button.tiny-switch-common.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 10px) !important;
}

.text-ellipsis {
  white-space: pre-wrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
}

.ant-select-multiple .ant-select-selection-search {
  max-width: 99%;
}

.text-bold-600 {
  font-weight: 600;
}
