@import 'styles/variables.scss';

.page-notification-detail {
  width: 800px;
  margin: auto;
  margin-top: 50px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 2px;
  min-height: 300px;

  .message-title {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-17;
    letter-spacing: 0.1px;
    color: #343434;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
  }

  .message-alert {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;

    display: flex;
    letter-spacing: 0.1px;
    color: #343434;
    margin: 40px 0 20px 0;
  }

  .message-files {
    width: 420px;
    border: 1.5px solid #21c0f6;
    height: 35px;
    border-radius: 4px;
    gap: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 10px;

    .text-message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
