@import "styles/variables.scss";
.modal-share-incidents {
  .select-email {
    .ant-select-selector {
      border-radius: 0.5*$space-size-base;

      .ant-select-selection-item {
        background: #e0e0e0;
        border-radius: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-13;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 16px */

        letter-spacing: 0.4px;
        align-items: center;
        /* Text / Primary */

        color: #343434;
      }
    }
  }

  .input-description {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* or 20px */

    letter-spacing: 0.5px;

    /* Text / Primary */

    color: #343434;
  }

  .expired-date {
    border-radius: 0.5*$space-size-base;
    padding-left: 4px;
    font-size: $font-size-15;
    
    .ant-picker-input{
      border-radius: 0.5 * $space-size-base;
      height: 40px;
      width: 20rem;
      flex-direction: row-reverse;
      justify-content: flex-end;
      
      .expired-date-container {
        margin-right: 8px;
        img.schedule-clock-icon {
          width: 20px;
          margin-right: 12px;
        }
        span.expired-date-text {
          vertical-align: middle;
          font-size: $font-size-15;
          color: black;
          margin-left: 4px;
        }
      }
      
      input {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        width: 8rem;

        color: #343434;
      }
      
      .ant-picker-suffix,
      .ant-picker-clear {
        font-size: $font-size-20;
        right: 8px;
        color: red;
      }
    }
    
    .calendar {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-base;
      border-radius: 0.5 * $space-size-base 0px 0px 0.5 * $space-size-base;
    }
    
  }

  .label-info {
    margin-top: $space-size-base;
    .ant-space-item {
      .txt {
        font-style: normal;

        font-size: $font-size-17;
        line-height: $line-height-rate-base;

        color: #007aff;
        text-transform: uppercase;
      }

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
        font-size: $font-size-20;
        margin-top: 5px;
      }
    }
  }

  .div-label-info {
    margin-top: 1.5*$space-size-base;
  }

  .ant-checkbox-wrapper {
    span:nth-child(2) {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      color: #000000;
    }
  }

  .ant-checkbox-checked::after {
    border: 1px solid #007aff;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007aff;
    border-color: #007aff;
  }
}
