@import "styles/variables.scss";

.setting {
  .admin-user-setup {
    padding: 2 * $space-size-base 2 * $space-size-base;

    .search-input {
      max-width: 320px;
      border-radius: 0.5 * $space-size-base;
      margin-bottom: 0.5 * $space-size-base;

      .ant-input-prefix {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .table-users {
      .ant-table-thead>tr>th {
        &::before {
          display: none;
        }

        font-style: normal;
        font-weight: normal;
        font-size: $font-size-14;
        line-height: $line-height-rate-nano;
        /* or 13px */

        letter-spacing: 0.1px;
        text-transform: uppercase;

        /* Text / Primary */

        color: #343434;
        background: #fff;

        .btn-new-user {
          min-width: 131px;
          height: 32px;

          span:nth-child(2) {
            font-style: normal;

            font-size: $font-size-15;
          }
        }
      }

      .ant-table-tbody>tr>td {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */
        padding: 1.5 * $space-size-base;

        /* Text / Primary */

        color: #343434;

        .anticon {
          color: #007aff;
        }

        .txt-inactive {
          color: #c6c6c8;
        }

        .space-role-tag {
          margin-left: 45px;
        }

        .role-tag {
          width: 32px;
          height: 32px;

          background: #f2f2f2;
          border-radius: 100px;

          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          padding: 0;
          padding: 0.5 * $space-size-base;
          box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
        }

        .txt-action {
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          text-align: center;
          letter-spacing: 0.1px;

          /* System / Blue */

          color: #007aff;
        }

        .ant-typography-disabled.txt-action {
          color: #c6c6c8;
        }

        .ic-lock {
          cursor: pointer;
        }
      }
    }
  }
}

.md-form-user {
  width: 1120px !important;

  .form-user-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 50px;

    .btn-lock {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;

      /* System / Red */

      color: #18bcf3;
      border: 1px solid #18bcf3;
    }
  }

  .item-status {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column !important;
      align-items: unset !important;

      .title-status {
        font-family: $font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        color: #343434;
      }

      .wrap-txt {
        margin-top: 0 !important;
      }
    }
  }

  .form-active {
    .ant-form-item-control-input-content {
      .ant-form-item {
        margin-bottom: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;

      .wrap-txt {
        margin-top: 20px;

        .txt-status {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
      }
    }
  }

  .label-info {
    margin-top: 18px;

    .ant-space-item {
      .txt {
        font-style: normal;

        font-size: $font-size-17;
        line-height: $line-height-rate-base;

        color: #007aff;
        text-transform: uppercase;
      }

      .ic-info {
        color: #007aff;
        font-size: $font-size-20;
        margin-top: 5px;
      }
    }
  }

  .div-label-info {
    margin-top: 1.5 * $space-size-base;
  }

  .input-info {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    color: #343434;
  }

  .radio-role {
    .ant-radio-wrapper {
      padding: 7px;
      min-width: 660px;

      span {
        &:nth-child(2) {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;

          letter-spacing: 0.1px;

          /* Primary Text */

          color: #343434;
        }
      }
    }

    .ant-radio-wrapper-checked {
      border-radius: 0.5 * $space-size-base;
      border: 1px solid #007aff;
      background: rgba(64, 158, 255, 0.1);

      .ant-radio-checked .ant-radio-inner {
        border-color: #007aff;

        &:after {
          background-color: #007aff;
        }
      }
    }
  }

  .role-oa {
    margin-bottom: 0;

    .ant-form-item-control-input {
      height: 0;
      min-height: 0;
    }
  }

  .space-input-phone {
    width: 100%;

    .ant-space-item:nth-child(1) {
      width: 84px;
    }

    .ant-space-item:nth-child(2) {
      width: 100%;
    }
  }

  .board {
    margin-top: 30px;
    margin-bottom: 75px;

    .ant-table-thead>tr>th {
      &::before {
        display: none;
      }

      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .ant-table-tbody>tr>td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .ic-expand {
        color: #007aff;
        margin-right: 1.5 * $space-size-base;
        font-size: $font-size-13;
      }

      .fake-expand {
        margin-right: $space-size-large;
        color: transparent;
      }

      .ant-checkbox-checked::after {
        border: 1px solid #007aff;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #007aff;
        border-color: #007aff;
      }

      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #e3e3e3;
      }

      .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: #fff;
        opacity: 0.5;
      }

      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
      }

      .access-limiter {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;

        color: #343434;
        width: 100%;
      }
    }
  }

  .switch-al {
    background-color: #ff3b30;
  }

  .switch-al.ant-switch-checked {
    background-color: #34c759;
  }
}

.md-deactive-user,
.md-unlock-user {
  width: 800px !important;

  .space-txt-deactive,
  .space-txt-unlock {
    margin-bottom: 40px;
  }

  .txt-deactive,
  .txt-unlock {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    /* or 25px */

    letter-spacing: 0.1px;
    white-space: pre-wrap;

    /* Text / Primary */

    color: #343434;
  }
}