@import 'styles/variables.scss';
@import '../components/detail-incidents/form-detail.scss';
@import '../components/detail-incidents/list-detail.scss';
@import '../components/detail-incidents/notes.scss';
@import '../components/detail-incidents/activitylog-detail.scss';
@import '../components/detail-incidents/modal-share.scss';
@import '../components/detail-incidents/chat.scss';

.detail-incidents {
  height: calc(100vh - 108px);

  /*overflow: hidden;*/
  .ant-tabs-nav {
    margin: 0;
  }

  .header-incidents {
    height: unset;
    padding: 0.5 * $space-size-base + 2 2 * $space-size-base;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*position: fixed;*/
    width: 100%;
    z-index: 99;

    .anticon-flag {
      font-size: $font-size-24;
      margin-left: 40px;
    }

    .header-info {
      display: flex;
      flex-direction: column;
      max-width: 1050px;
      min-width: 40%;

      .info {
        display: flex;
        margin-right: 28px;
        position: relative;
        margin-top: 4px;
      }

      .info-title {
        font-size: $font-size-18;
        line-height: $line-height-rate-micro;
        letter-spacing: 0.2px;
        color: #343434;
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 8px;
        min-width: 148px;
        max-width: 50%;
      }

      .info-org-name {
        max-width: 50%;
        min-width: unset;
      }

      .title-left::after {
        content: '.';
        position: absolute;
        top: -$space-size-base;
        margin-left: 10px;
        color: #c4c4c4;
      }

      .text-left:not(:last-child)::after {
        content: '.';
        position: absolute;
        font-size: 2rem;
        top: -2.5 * $space-size-base;
        padding-left: 10px;
        margin: auto;
        color: #c4c4c4;
      }

      .text-left {
        min-width: 140px;
        position: relative;

        &._bottom {
          margin: initial;
        }
      }

      .info-text {
        padding-right: 28px;
        margin: auto;
        font-size: $font-size-14;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.1px;
        color: #818181;
        position: relative;
      }

      .text-closed {
        color: #ff9500;
        padding-right: 28px;
        margin: auto;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.1px;
        white-space: pre-line;
      }
    }

    .header-box-center {
      line-height: initial;
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: $font-size-12;
      overflow: hidden;

      .text-close {
        color: red;
      }
    }

    .header-list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 256px;
      width: 100%;

      .ant-image {
        cursor: pointer;
        margin-left: 40px;

        @media (max-width: 1500px) {
          margin-left: 20px !important;
        }
      }
    }
  }

  .container {
    overflow: hidden;
    height: 100%;

    .row {
      height: 100%;

      .col-left {
        max-height: 100%;
        background-color: #fff;
        border-right: 1px solid #f0f0f0;
        display: flex;
        flex-direction: column;

        &.detail {
          max-height: 100%;
        }
      }

      .col-right {
        overflow: auto;
        max-height: 100%;
        background-color: #fff;
        padding: 2 * $space-size-base;
      }
    }
  }

  .tabs {
    height: 100%;

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding: 0 2 * $space-size-base;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #18c9f3 !important;
    }

    .ant-tabs-ink-bar {
      background: #18c9f3;
    }

    .ant-tabs-tab-btn {
      font-style: normal;

      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;

      &:hover {
        color: #18c9f3;
      }
    }

    .tab-pane {
      height: 100%;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-spin-container {
      height: 100%;
    }
  }
}

.ant-spin-nested-loading {
  height: 100%;
}

.flag {
  right: 95px;
  overflow: scroll;
}

.menu_list {
  min-width: 326px;
  height: 100%;
  max-height: 398px;
  background: #ffffff;
  border: 1px solid transparent;
  margin-top: 2 * $space-size-base;
  border-radius: 0.5 * $space-size-base;
  box-sizing: border-box;
  overflow: auto;

  .menu_item {
    height: 46px;
    display: flex;
    align-items: center;

    .ant-dropdown-menu-title-content {
      font-size: $font-size-17 !important;
      line-height: $line-height-rate-base;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }

  .menu_item:hover {
    background: rgba(64, 158, 255, 0.1);
  }
}

.ant-popover-inner-content {
  color: #ffffff !important;
}

.dropdown-menu-export,
.dropdown-menu-priority {
  z-index: 11111;
}
