@import 'styles/variables.scss';
@import 'emoji-mart/css/emoji-mart.css';

.chat-container {
  width: 560px;
  height: 576px;
  filter: drop-shadow(0px 0px 4px #c6c6c8);
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  display: flex;
  flex-direction: column;

  .chat-header {
    cursor: move;
    height: 60px;
    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
    display: flex;
    padding: 2 * $space-size-base;
    justify-content: space-between;
    align-items: center;

    .title {
      font-style: normal;

      font-size: $font-size-21;
      line-height: $line-height-rate-micro;
      /* or 28px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2px;

      /* Text / Primary */

      color: #343434;
    }

    .ic-close,
    .ic-minimize {
      cursor: pointer;
    }
  }

  .chat-header.minimize {
    cursor: pointer;
    background: #e0dede;
    /* Inner Shadow / Cell Table */
    border: none;
  }

  .chat-content {
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;


    .inifinite-scroll {
      padding: 2 * $space-size-base 2 * $space-size-base 0 2 * $space-size-base;
      display: flex;
      flex-direction: column-reverse;

      .bubble-chat {
        margin-bottom: 2 * $space-size-base;

        .ant-comment-inner {
          max-width: 90%;
          padding: 0;

          .space-content-bubble {
            display: flex;
            background: rgba(144, 147, 153, 0.1);
            border-radius: 1.5 * $space-size-base;
            max-width: 300px;

            .ant-space-item {
              display: flex;
              // justify-content: center;
              align-items: center;

              .container_text {
                padding-top: 1.5 * $space-size-base;
                padding-right: 1.5 * $space-size-base;
                padding-left: 1.5 * $space-size-base;

                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
              }
            }

            .txt-message {
              color: #343434;
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-nano;
              /* or 20px */

              letter-spacing: 0.5px;
              white-space: pre-line;

              div {
                white-space: break-spaces !important;
              }

              .ic-delete {
                width: 16px;
                height: 16px;
              }

            }

            .anonymouse {
              .ant-space-item:first-child {
                .ant-typography {
                  max-width: unset !important;
                }
              }
            }

            .space-info-bubble {
              display: inline-flex;
              padding-left: 1.5 * $space-size-base;
              padding-right: 1.5 * $space-size-base;
              padding-bottom: 1.5 * $space-size-base;

              .ant-space-item:first-child {
                display: flex;

                .ant-typography {
                  max-width: 70px;
                }
              }

              .ant-typography {
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-10;
                line-height: $line-height-rate-nano;
                /* identical to box height, or 12px */

                text-align: right;
                letter-spacing: 0.1px;

                color: #343434;
              }
            }
          }
        }
      }

      .bubble-chat.owner {
        display: flex;
        justify-content: flex-end;

        .ant-comment-inner {
          .txt-message {
            color: #fff;
          }

          .space-content-bubble {
            align-items: flex-end;
            background: #21c0f6;

            .space-info-bubble {

              .ant-typography,
              .ant-space-item-split {
                color: #fff;
              }
            }
          }



          .is-delete {
            background: #DEDEDE;

            .txt-message {
              color: #777777;
              display: flex;
              justify-content: center;
            }

            .space-info-bubble {
              padding-bottom: 0;

              &.space-info-bubble-delete {
                padding-bottom: 12px;
              }

              .ant-typography,
              .ant-space-item-split {
                color: #343434;
              }
            }

          }
        }
      }
    }
  }

  .chat-div {
    height: 100%;

  }

  .space-input-message {
    border-top: 1px solid #e6e4ea;
    width: 100%;
    padding: 0 2 * $space-size-base;
    height: 100%;

    .ant-space-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .input-message {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.5px;
        color: #343434;
        border: none;
        padding-top: 6px;
        height: 100%;
        resize: none;

        /*        &:focus {
          border: 1px solid #007aff;
          &::placeholder {
            padding-top: 0px !important;
          }
        }
        &::placeholder {
          padding-top: 20px;
        }*/
      }

      .ic-send,
      .ic-emoji {
        cursor: pointer;
      }
    }

    .ant-space-item:nth-child(1),
    .ant-space-item:nth-child(3) {
      width: 10%;
    }
  }
}

.chat-container.minimize {
  height: 60px;
}

.onBlurContainer {
  display: flex;
  justify-content: space-between;
}

.form-area {
  .is_active {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2 * $space-size-base;

    .ant-space-item {
      width: 100% !important;

      .area {
        width: 100%;
        border: 1px solid #007aff;
        height: 124px !important;
        max-height: 124px !important;
      }
    }
  }
}

.ant-popover-inner-content {
  .msg-action {
    .action {
      color: #CE3333 !important;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      .ic-delete {
        margin-right: 3px;
      }
    }

  }
}

.modal-delete-chat {
  .ant-modal-body {
    text-align: center;
    color: #343434 !important;
    font-weight: 700 !important;
    font-size: 15px !important;

  }

  .ant-modal-footer {
    text-align: center;
  }
}