@import 'styles/variables.scss';


.activity-volume {
  .ant-space-vertical {
    width: 100%;
    .text-ellipsis {
      padding: 0 10px 0 0
    }
  }
}

.reports {
  .activity-volume {
    .activation-volume-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;
        .ant-tabs-nav-wrap {
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          padding: 0 2 * $space-size-base;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #18c9f3 !important;
        }
        .ant-tabs-ink-bar {
          background: #18c9f3;
        }
        .ant-tabs-tab-btn {
          font-style: normal;

          font-size: $font-size-17;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1px;

          &:hover {
            color: #18c9f3;
          }
        }
      }
    }
  }

  .timeline {
    padding: 1.5 * $space-size-base 2 * $space-size-base;

    .timeline-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .range-picker {
        border-radius: 0.5 * $space-size-base;
        width: 375px;
        .ant-picker-input {
          input {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            letter-spacing: 0.5px;
          }
        }

        .ant-picker-suffix,
        .ant-picker-clear {
          font-size: $font-size-20;
        }

        .ant-picker-clear {
          margin-right: 28px;
        }
      }

      .select-time {
        label {
          font-style: normal;

          font-size: $font-size-15;
        }
        .ant-radio-button-wrapper:first-child {
          border-radius: 0.5 * $space-size-base 0 0 0.5 * $space-size-base;
        }

        .ant-radio-button-wrapper:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }

    .timeline-table {
      .ant-table-thead > tr > th {
        &::before {
          display: none;
        }
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-14;
        line-height: $line-height-rate-nano;
        /* or 13px */

        letter-spacing: 0.1px;
        text-transform: uppercase;

        /* Text / Primary */

        color: #343434;
        background: #fff;
      }

      .ant-table-tbody > tr > td {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */
        padding: 0 1.5 * $space-size-base;

        letter-spacing: 0.1px;
        height: 48px;
        /* Text / Primary */

        color: #343434;

        .space-timeline {
          .date-month,
          .range-date {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            letter-spacing: 0.1px;
          }

          .range-date {
            font-size: $font-size-13;
          }
        }
      }

      .ant-table-cell {
        padding: 0.5 * $space-size-base 1.5 * $space-size-base;
      }

      .row-odd {
        background: rgba(144, 147, 153, 0.1);
      }

      .row-summary {
        background: rgba(64, 158, 255, 0.1);

        td {
          height: 48px;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          color: #343434;
        }

        .cell-summary {
          text-align: right;
        }
      }
    }
  }

  .active-by-organization {
    .ant-col {
      border-right: 1px solid rgba(144, 147, 153, 0.2);
      border-bottom: 1px solid rgba(144, 147, 153, 0.2);
    }
    .head {
      display: flex;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid rgba(144, 147, 153, 0.2);
      padding: $space-size-base 1.5 * $space-size-base;

      .search-input {
        width: 100%;
        border-radius: 0.5 * $space-size-base;
        height: 32px;

        .ant-input-prefix {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .date-picker {
        width: 100%;
        border-radius: 0.5 * $space-size-base;
        height: 32px;
        .ant-picker-input {
          input {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
          }

          .ant-picker-suffix,
          .ant-picker-clear {
            font-size: $font-size-20;
          }

          .ant-picker-clear {
            margin-right: 28px;
          }
        }
      }
    }

    .head.last-col {
      border-right: none;
    }

    .view-add {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .space-view {
        margin-top: 100px;
        max-width: fit-content;
        .ic-add {
          cursor: pointer;
        }

        .txt-add {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-17;
          line-height: $line-height-rate-base;
          letter-spacing: 0.1px;
        }
      }
    }
    
    .table-organization,
    .table-calculate {
      :-webkit-scrollbar {
        display: none;
      }
      .ant-table-thead > tr > th {
        &::before {
          display: none;
        }
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-14;
        line-height: $line-height-rate-nano;
        /* or 13px */

        letter-spacing: 0.1px;
        text-transform: uppercase;

        /* Text / Primary */

        color: #343434;
        background: #fff;
        padding: 16px 6px;

        .switch-hidden {
          background-color: #ff3b30;
          height: 10px;
          line-height: 10px;
          margin-bottom: 2px;

          .ant-switch-handle {
            width: $space-size-base;
            height: $space-size-base;
            top: 1px;
          }
        }

        .switch-hidden.ant-switch-checked {
          background-color: #34c759;
        }
      }

      .ant-table-tbody > tr > td {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */
        padding: space-size-base 2 * $space-size-base;
        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }

      .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
        background: unset;
      }
      .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
        background: unset;
      }

      .row-org {
        background: #ffdeeb;
      }

      .row-school {
        background: #a8e8fe;
      }

      .txt-hidden {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-13;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}

.ant-table-tbody > tr > td {
  height: 55.5px;
}

.dropdown-range-picker {
  .ant-picker-panel-container {
    display: flex;
    flex-direction: row-reverse;

    .ant-picker-footer {
      min-width: unset;

      .ant-picker-ranges {
        width: 192px;
        padding-right: 0;

        .ant-picker-preset {
          margin: 10px 0;
          width: 100%;
          .ant-tag-blue {
            border: none;
            background: none;
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            color: #27272a;
            word-break: keep-all;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}
