@import 'styles/variables.scss';
.detail-incidents {
  .files-item {
    cursor: pointer;
  }
  .files-drag {
    position: relative;
    height: 100%;
    .space-file-upload {
      background: #fff;
      padding: $space-size-base 0;
      .upload-attachment-title {
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #343434;
        padding: 10px 2 * $space-size-base;
        text-transform: uppercase;
        margin: 0;
        box-shadow: inset 0px -2px 0px rgba(144, 147, 153, 0.2);
      }
      .space-file {
        padding: 2 * $space-size-base;
      }
      // .container-file {
      //   overflow-y: scroll !important;
      //   overflow-x: hidden;
      //   max-height: 100px;
      //   &::-webkit-scrollbar {
      //     display: block !important;
      //     width: 4px !important;
      //   }
      //   &::-webkit-scrollbar-thumb {
      //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      //   }
      //   .ant-space-item:last-child {
      //     align-self: unset !important;
      //   }
      // }
    }
    .space-file {
      padding: 2 * $space-size-base;
      display: flex;
      width: 100%;
      .ant-space-item:last-child {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
      .top-info {
        display: flex;
      }
      .file-name {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        letter-spacing: 0.1px;
        color: #343434;
        margin-right: 7px;
      }

      .file-name.error {
        color: #ff3b30;
      }

      .ic-download {
        cursor: pointer;
        margin-right: 7px;
        margin-bottom: 20px;
      }
    }

    .upload-info {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      .space-upload__detail {
        display: flex;
        .ant-space-item:first-child {
          width: 100%;
          .ant-typography {
            max-width: 500px;
          }
        }
      }
    }

    .upload-list-incidents {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      position: fixed;
      width: 50%;
      bottom: 40px;
      justify-content: center;
      cursor: pointer;
      background: #fff;
      min-height: 64px;
      max-height: 64px;
      display: flex;
      align-items: center;
      transition: min-height 0.6s;
      box-shadow: inset 0px 1px 0px #e6e4ea;
      .ant-upload-btn {
        padding: 0;
        min-height: 62px;
      }
      .ant-upload-list-item-card-actions {
        display: none;
      }
      .txt-drop,
      .txt-browse {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-13;
        line-height: $line-height-rate-base;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.1px;

        color: #000000;
      }

      .txt-browse {
        color: #007aff;
      }
    }
    .forms {
      height: fit-content;

      .item-list-question {
        flex: 1 1 auto;
        height: 89%;
        flex-flow: unset !important;
      }
      .ant-row {
        flex-flow: unset;
      }
      span {
        // flex: 1 1 0px;
        .ant-upload-list {
          display: none;
        }
      }
      .upload-incidents {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0px 1px 0px #e6e4ea;
        min-height: 50px;
      }
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: rgba(64, 158, 255, 0.1);
  }
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }
  .detail-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2 * $space-size-base;
      margin-bottom: 10px;
      padding: 0 2 * $space-size-base;

      .space-other-incident {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .list-title {
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      letter-spacing: 0.1px;
      color: #343434;
    }
    .list-text {
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      color: #18bcf3;

      display: flex;
      align-items: center;
      justify-content: center;
      .ant-image {
        margin-right: 9px;
      }
    }
    .detail-question-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
      border-radius: 0.5 * $space-size-base;
      padding: $space-size-base 2 * $space-size-base;

      .detail-question-title {
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.1px;
        color: #232326;
        padding: 2 * $space-size-base 0;
        margin: 0;
        background: rgba(64, 158, 255, 0.1);
        padding: $space-size-base 2 * $space-size-base;
        border-radius: 0.5 * $space-size-base 0.5 * $space-size-base 0 0;
      }
      .detail-question-text {
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        letter-spacing: 0.1px;
        color: #343434;
      }
      .space {
        width: 100%;
        .list-question {
          background: #ffffff;
          box-shadow: 0px 0px 0.5 * $space-size-base #c6c6c8;
          border-radius: 0.5 * $space-size-base;
          padding: 4 * $space-size-base;
          width: 100%;
          height: 100%;

          .question-title {
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            letter-spacing: 0.1px;
            color: #343434;
            margin-bottom: 1.5 * $space-size-base;
            white-space: pre-wrap;
          }
          .question-text {
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            letter-spacing: 0.1px;
            color: #343434;
            white-space: break-spaces !important;
          }
        }
      }
    }
  }

  .forms {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-upload-text-icon {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input {
      height: 100%;
    }
    .ant-form-item-control-input-content {
      height: 100%;
    }
    .upload-incidents {
      cursor: pointer;
    }
    .txt-browse {
      margin-left: 4px;
    }
  }

  .files-placeholder {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ecf5ff;
    border-radius: 0.5 * $space-size-base;
    text-align: center;
    border: 1px dashed #007aff;
    border-radius: unset;
  }
  .txt-drop,
  .txt-browse {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.1px;
  }
}

.container-question {
  height: calc(100vh - 390px);
  min-height: 210px;
  background: rgba(64, 158, 255, 0.1);
  border-radius: 0.5 * $space-size-base;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .space:first-child {
    flex: 1;
    .ant-space-item:last-child {
      margin-bottom: 2 * $space-size-base;
    }
  }
  .space {
    .ant-space-item {
      padding: 0 2 * $space-size-base;
      .ant-space-item {
        padding: 0;
      }
    }
  }
}
.ant-tooltip {
  z-index: 10000000000000000000000000;
}
.img-upload {
  margin-right: 8px;
}
.checkbox-view {
  font-size: $font-size-15;
  font-weight: 600;
  span:last-child {
    max-width: 100%;
    overflow: hidden;
    text-overflow: clip;
    word-break: break-word;
    font-size: $font-size-15;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: #343434;
  }
}
